<template>
    <div class="app-bar-menu-locale">
        <v-menu
            offset-y
            bottom
            left
        >
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-web</v-icon>
                {{$vuetify.lang.current}}
            </v-btn>
            </template>
            <v-list>
                <v-list-item-group v-model="model">
                    <v-list-item
                        v-for="item in langs"
                        :key="item"
                        v-on:click="changeLocale(item)"
                        >
                        <v-list-item-content>
                            <v-list-item-title v-text="$t('languages.'+item)"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
  </div>
</template>


<script>
    export default {
        name: 'AppBarMenuLocale',

        data () {
            return { 
                langs: this.loadLocaleLanguages () ,
                model: this.$vuetify.lang.current,
            }
        },
        methods: {
            changeLocale (value) {
                this.$root.$i18n.locale = value
                this.$vuetify.lang.current = value
            },
            loadLocaleLanguages () {
                const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
                const languages = []
                locales.keys().forEach(key => {
                    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
                    if (matched && matched.length > 1) {
                    const locale = matched[1]
                    languages.push(locale)
                    }
                })
                return languages
            }
        },
    }

</script>